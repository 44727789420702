<template>
    <div class='Propage'>
        <!-- <div v-if="newList">
            
            <div class='proMain' v-for='item in newList' :key='item.type'>
                <a @click="gopayUrl(item.contractUrl,item.contractName)" class='prolink'>
                        <div class='proLine'>
                            <div class='proText'>{{item.contractName}}</div>
                            <div class='proIcon'></div>
                        </div>
                </a>
            </div>
        </div> -->
        <!-- 借款前对的协议 :href="item"-->
       <!-- <div v-if="newObj!=null">
            <div class='proMain' v-for='(item,index) in newObj' :key='item.key'>
                <a  class='prolink' @click="goloanUrl(item,index)">
                        <div class='proLine'>
                            <div class='proText'>{{index}}</div>
                            <div class='proIcon'></div>
                        </div>
                </a>
            </div>
       </div> -->
    <!-- 产品详情协议 -->
        <div>
            <div class='proMain' v-for='item in proList' :key='item.protocolNo'>
                <a  class='prolink' @click="goloanUrl(item.protocolUrl)">
                        <div class='proLine'>
                            <div class='proText'>{{item.protocolName}}</div>
                            <div class='proIcon'></div>
                        </div>
                </a>
            </div>
       </div>
    </div>
</template>
<style scoped lang='less' src='./index.less'></style>
<script>
import { onMounted, toRefs,reactive } from "vue"
import APP from '../../utils/APP'
import API from '../../server/api'
import { GetUrlParams, isEmptyObj } from '../../utils/index'

export default ({
    setup() {
        const state=reactive({
            newList:[],
            newObj:null,
            proList:[]
        })
       
        const init=()=>{
         
            state.proList=JSON.parse(window.localStorage.getItem('protocol'))
            // APP.Alert(state.proList) 
            // console.log(state.proList,9655);
        }



        // const payInits=async()=>{
        //     const params = GetUrlParams()
        //     const {type}=params
        //     if(type=="2"){
        //         // 获取还款协议
        //         const loanNo =window.localStorage.getItem('loanNo');
        //         const agreement=await API.loanAttach({
        //             loanNo
        //         })
        //         state.newList=agreement
        //     }else{
        //          //获取借款前的协议
        //         const clientNo = window.localStorage.getItem('clientNo')
        //         const productName = window.localStorage.getItem('productName')
        //         const result=await API.loanAgreements({
        //             clientNo,
        //             productName
        //         })
        //         const strobj=Object.values(result).join('')
        //         console.log(typeof(strobj));
        //         const p=JSON.parse(strobj)
        //         state.newObj=p
        //     }
            
           
           

        // }
     
        const goloanUrl=(item,items)=>{
            // window.open(item)
           APP.JUMP(item,items)
        }
        const gopayUrl=(item,items)=>{
            APP.JUMP(item,items)
            // window.open(item)
        }
        onMounted(()=>{
           
            APP.SET_TITLE('协议')
          
           
            
            init()
            // initpay()
            // payInits()
             
        })
        return{
            ...toRefs(state),
            init,
            // payInits,
            goloanUrl,
            gopayUrl
        }
    },
})
</script>
